* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: $fontSize;
    color: $textColor;
    background-color: $bodyBgColor;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
}

.layout-wrapper {
    padding: 0;
    min-height: 100vh;
}

.column-filter {
    width: 100%;
}

.tableCursorPointer tbody {
    cursor: pointer;
}

.tablePadding tr td {
	padding: 0.2em;
}

.p-datatable-loading-overlay {
	background-color: transparent;
}

.p-dropdown-panel {
	z-index: 9001 !important;
}

.p-fileupload-basic {
    .p-fileupload-choose {
        width: 100%;
    }
}

.p-column-filter-row .p-column-filter-element {
    width: 100%;
}

.p-component {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-weight: 400;
}

.p-rowgroup-header td {
    min-width: 100%;
    width: 100%
}

.p-datatable .p-datatable-header {
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
}

.p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.p-datatable-produktstammdaten .p-datatable-tbody > tr > td {
  padding-left: 0.857rem;
}

.p-datatable-gutscheine .p-datatable-tbody > tr > td {
  padding-left: 0.857rem;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.5rem 0.5rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    transition: box-shadow 0.2s;
  }

.p-datatable.p-datatable-gridlines .p-datatable-header {
border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background-color: #ecebeb;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background-color: rgb(190, 190, 190);
}

/***
Paginator
***/

.p-paginator {
    background: #ffffff;
    color: #6c757d;
    border: solid #e9ecef;
    border-width: 0;
    padding: 0;
    border-radius: 6px;
    font-size: 0.8em;
  }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: transparent;
    color: #343a40;
  }
  .p-paginator .p-link {
    font-size: 0.9rem;
  }
  .p-paginator .p-paginator-first {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .p-paginator .p-paginator-last {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }
  .p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 3rem;
  }
  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #EEF2FF;
    border-color: #EEF2FF;
    color: #4338CA;
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #e9ecef;
    border-color: transparent;
    color: #343a40;
  }

  /*
.p-datatable-preise  .p-column-header-content {
    justify-content: center;
}
*/

.p-datatable-warenbestand .p-datatable-tfoot > tr > td {
  border-width: 0px !important;
}

.layout-menu li {
  font-size: 13px;
}

.p-column-header-content-center .p-column-header-content
{
  justify-content: center;
}