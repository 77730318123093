.login {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    margin-top: 50px;
}

.loginInput {
    border-style: none;
    border-bottom-style: solid;
    border-radius: 0px;
    text-align: center;
}

.loginPos {
    width: 15em;
    margin-left: calc(50% - 7.5em);
}